import _ from 'lodash';
import { schemaShouldShowConfigTypes, schemaTypes } from './config';

const schemaToLabelMap = {
  stopLoss: {
    isDisplay: true,
    displayName: 'Current Transaction Loss %',
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ],
  },
  stopGain: {
    isDisplay: true,
    displayName: 'Current Transaction Profit %',
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ],
  },
  stopLossAmount: {
    isDisplay: true,
    displayName: 'Current Transaction Loss Amount',
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ],
  },
  stopGainAmount: {
    isDisplay: true,
    displayName: 'Current Transaction Profit Amount',
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ],
  },
  lastTransactionLossPercent: {
    isDisplay: true,
    displayName: 'Last Transaction Loss %',
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.entryLeftOperand,
      schemaShouldShowConfigTypes.exitLeftOperand,
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
    ],
  },
  lastTransactionGainPercent: {
    isDisplay: true,
    displayName: 'Last Transaction Profit %',
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.entryLeftOperand,
      schemaShouldShowConfigTypes.exitLeftOperand,
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
    ],
  },
  lastTransactionLossAmount: {
    isDisplay: true,
    displayName: 'Last Transaction Loss Amount',
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.entryLeftOperand,
      schemaShouldShowConfigTypes.exitLeftOperand,
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
    ],
  },
  lastTransactionGainAmount: {
    isDisplay: true,
    displayName: 'Last Transaction Profit Amount',
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.entryLeftOperand,
      schemaShouldShowConfigTypes.exitLeftOperand,
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
    ],
  },
  lastTransactionDuration: {
    isDisplay: true,
    displayName: 'Last Transaction Duration',
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.exitLeftOperand,
      schemaShouldShowConfigTypes.entryLeftOperand
    ],
  }
};

export const buildStrategyStopOutputSchema = () => {
  return _.chain(schemaToLabelMap)
    .pickBy(({ isDisplay }) => isDisplay)
    .map((config, name) => {
      return {
        type: schemaTypes.number,
        category: 'strategyStop',
        name,
        label: config?.displayName,
        shouldShowConfigs: config?.shouldShowConfigs,
        priority: 200,
        schemaName: name
      };
    })
    .value();
};
